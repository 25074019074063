module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-KZEF9SPKNX","head":true,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Start and Finish Each Day With Good Vibes","short_name":"Felix.fm","start_url":"/","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"88857a7010f1606cd64dcea8b3384613"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
